@media (max-width: 1500px) {
  .patient-queue-table {
    overflow-x: scroll;
    position: absolute;
    width: max-content;
  }
}

.submitButton {
  background: blue !important;
  color: white !important;
}

.filters-grid {
  margin-bottom: 1em;
}

.alignTextCenter {
  text-align: center;
}

.selectFieldStyle {
  width: 20em;
}

.accordion-heading {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--text-primary, rgba(0, 0, 0, 0.87));
  font-size: 18px !important;
  font-style: normal;
  font-weight: 700 !important;
  line-height: 157%; /* 28.26px */
}

.go-to-message-btn {
  color: #1d1d1d;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 14px */
  letter-spacing: 0.14px;
  padding: 4px 8px;
  background-color: transparent;
  border: 1px solid #1d1d1d;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.go-to-message-btn:hover {
  background-color: rgba(29, 29, 29, 0.1);
}

.accordion-details {
  padding: 12px 12px 24px 36px !important;
}

.section-heading {
  color: var(--text-primary, rgba(0, 0, 0, 0.87));
  font-size: 14px !important;
  font-style: normal;
  font-weight: 700 !important;
  line-height: 157%; /* 21.98px */
  letter-spacing: 0.113px;
  margin-bottom: 8px;
}

.section-paragraph {
  color: var(--text-primary, rgba(0, 0, 0, 0.87));
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 157%; /* 21.98px */
  letter-spacing: 0.113px;
  margin-bottom: 12px !important;
}
