.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.timeline-verticalstyles__TimelineVerticalWrapper-sc-1427v1d-0 {
  padding: 0 !important;
}
.timelinestyle__TimelineMainWrapper-sc-cif21b-1{
  padding: 0 !important;
}
.timeline-verticalstyles__TimelineTitleWrapper-sc-1427v1d-5{
  width: 10% !important; /* Removes the default width */
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* Hide the scrollbar while still allowing scrolling */
.DialogContent {
  scrollbar-width: thin !important; /* For Firefox */
  scrollbar-color: transparent transparent; /* For Firefox */

  /* For WebKit browsers (Chrome, Safari) */
  &::-webkit-scrollbar {
    width: 0; /* Remove scrollbar width */
    background: transparent; /* Transparent background */
  }
}

.ScrollDesign {
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px #f5f5f5;
    border-radius: 10px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar {
    width: 12px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px #c1bcbc;
    background-color: #c1bcbc;
  }
}

/* CSS FOR DRAWER IN SOCIAL NEED */
/* ChronoStyles.css */
.chrono-timeline-line {
  border-top: 1px dotted #000000; /* Black dotted line */
}

.chrono-card-content {
  border: 1px solid #000000; /* Optional: border color for cards */
}

/* Optional: Adjust circle color if necessary */
.chrono-timeline-circle {
  background-color: #000000; /* Black color for the circles */
}

.ToolbarWrapper-sc-cif21b-6.fwRzpx {
  display: none !important;
}

.outer-container-last {
  display: flex;
  flex-direction: row;
  justify-content: space-between !important;
  justify-items: center;
  gap: 20rem;
  flex-wrap: wrap;
}
.outer-container {
  display: flex;
  flex-direction: row;

  gap: 50px; /* Space between rows */
  justify-content: flex-start;
}

.row {
  display: flex;
  gap: 1px; /* Space between items */
}

.inner-card {
  display: flex;
  flex-direction: column;
  padding: 1px;
}

.TimelineContentDetailsWrapper-sc-d7qjm1-7 {
  align-items: start !important;
}

.inner-card-last {
  display: flex;
  flex-direction: column;
}

.inner-card button {
  background-color: black;

  color: white;
  padding: 13px;
  border: none;

  cursor: pointer;
  margin-bottom: -8px;
  margin-top: 25px;
  font-size: 16px;
  font-weight: bold;
  transition: background-color 0.3s ease;
  align-self: flex-end;
}

.inner-card button:hover {
  background-color: #333;
}

.inner-card h5 {
  margin-bottom: -8px;
  margin-top: 11px;
}

.inner-card p {
  font-size: 13px;
}
.icon-container {
  display: flex;
  justify-content: center;
  gap: 5px;
  cursor: pointer;
}

.cursor-click {
  cursor: pointer;
}

.centered-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #f5f5f5;
  border: none;
  cursor: pointer;
}

.centered-button span {
  margin-left: 8px;
}

.outer-container-billing {
  display: flex;
  justify-content: center;
  align-items: center;
}

.center-content-billing {
  text-align: center;
  margin-left: 15rem;
  opacity: 0.5;
  height: 22vh;
}

.colum h5 {
  margin-bottom: -11px;
  margin-right: 2rem;
}

.outer-billing {
  display: flex;
}
.outer-billing p {
  font-size: 13px;
  margin-bottom: -2px;
}
.paragrah {
  margin-top: 8px;
}

.outer-billing {
  display: flex;
  justify-content: space-between; /* Space out the columns and button */
}

.colum:last-child {
  margin-right: 0;
}

.outer-billing p {
  font-size: 13px;
}

.button-generate {
  background-color: black;
  color: white;
  border: none;

  padding: 6px 20px;
  cursor: pointer;
  align-self: start;
  margin-left: 4.2rem;
  margin-top: 2rem;
}

.button-generate:hover {
  opacity: 0.8;
}

.view-notes {
  display: inline-flex;
  align-items: center;
  font-size: 13px;
}

.view-icon {
  margin-left: 5px;
  cursor: pointer;
  margin-top: 20px;
}
