$font-family: var(--font-family);

.sv_progress-toc {
  padding: 8px;
  max-width: 336px;
  height: 100%;
  background: #FFFFFF;
  border-right: 1px solid #D6D6D6;
  box-sizing: border-box;
  min-width: 256px;

  .sv-list__item.sv-list__item--selected .sv-list__item-body {
    background: rgba(25, 179, 148, 0.1);
    color: #161616;
    font-weight: 400;
  }

  .sv-list__item span {
    white-space: break-spaces;
  }

  .sv-list__item-body {
    padding-inline-start: 16px;
    padding-inline-end: 16px;
    border-radius: 3px;
    padding-top: 12px;
    padding-bottom: 12px;
  }
}