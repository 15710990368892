.node {
  border: 1px solid black;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
  padding: 12px;
  width: 470px;
  text-align: center;
  font-weight: bold;
  color: #0984e3;
  cursor: pointer;
  line-height: 1.2;
  margin-top: 15px;
}

.node:hover {
  background: radial-gradient(
    circle at 18.7% 37.8%,
    rgb(252, 252, 252) 0%,
    rgb(239, 241, 243) 90%
  );
}
.nodeBool {
  /*  border: 1px solid #0984e3;*/
  /*  border-radius: 4px;*/
  background-color: #fff;
  /*  box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;*/
  /*  padding: 12px;*/
  width: 470px;
  text-align: center;
  font-weight: bold;
  color: #0984e3;
  cursor: pointer;
  line-height: 1.2;
}

.nodeBool:hover {
  /*  background: radial-gradient(circle at 18.7% 37.8%, rgb(252, 252, 252) 0%, rgb(239, 241, 243) 90%);*/
}

.content {
  pointer-events: none;
}

.handle {
  visibility: hidden;
}

.placeholder {
  width: 470px;
  background: #fff;
  border: 1px dashed #bbb;
  color: #bbb;
  box-shadow: none;
}
